<template>
  <div>
    <v-card
      v-if="!is_unsubscribe"
      class="mx-auto"
      max-width="80%"
      style="margin:40px;"
    >
      <v-card-text>
        <div>INDEX NOSLUŠ s.r.o.</div>
        <p class="text-h4 text--primary">
          Newsletter
        </p>
        <p>V případě odhlášení již nebudete dostávat aktuální informace a novinky od našeho týmu. Pokud byste se v budoucnu rozhodl/a znovu přihlásit k odběru, budeme velmi rádi.</p>
        <div class="text--primary" style="text-align:center;">
          <h3 style="margin-top:50px;">
            Jste si opravdu jistý/á, že se chcete odhlásit z našeho odběru novinek?
          </h3>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row style="text-align:right;">
          <v-col :cols="12">
            <v-btn
            text
            color="primary teal accent-4"
            @click="unsubscribe()"
          >
            Áno, odhlásit
          </v-btn>

          <v-btn
            text
            color="teal accent-4"
            @click="redirectURL()"
          >
            Neodhlasovat
          </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card
      v-else
      class="transition-fast-in-fast-out v-card--reveal mx-auto"
      max-width="80%"
      style="margin:40px;"
    >
      <v-card-text class="pb-0">
        <p class="text-h4 text--primary">
          Odhlášení
        </p>
        <p style="padding:30px">Vaše e-mailová adresa byla úspěšně odhlášena z odběru. Mrzí nás to a velmi bychom se potěšili, pokud se v budoucnu rozhodnete znovu přihlásit k odběru našich novinek.</p>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-row style="text-align:right">
          <v-col>
            <v-btn
              text
              color="primary teal accent-4"
              @click="redirectURL()"
            >
              V pořádku
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
import axios from 'axios'
import { mdiAccountGroup, mdiFileDocumentEditOutline, mdiSortVariantRemove, mdiExportVariant, mdiTimerOutline } from '@mdi/js'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response],
  data() {

    return {
      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      // vcard tabs
      tabs_h: 400,

      // Icons
      icons: {
        mdiAccountGroup,
        mdiFileDocumentEditOutline,
        mdiSortVariantRemove,
        mdiExportVariant,
        mdiTimerOutline,
      },

      is_unsubscribe: false,
    }
  },
  computed: {

  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize)
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
  },
  methods: {
    snackBarMsg(p_data) {
      this.snackbar = true
      this.snack_color = p_data.snack_color
      this.snack_text = p_data.snack_text
    },
    windowResize() {
      // window.innerWidth
      this.tabs_h = window.innerHeight - 180
      console.log('tabs_h =>', this.tabs_h)
    },
    redirectURL() {
      window.location.href = 'https://indexnoslus.cz'
    },
    unsubscribe() {
      let config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/unsubscribe/${this.$route.params.email_address}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          this.is_unsubscribe = true
        })
        .catch(err => {
          this.is_unsubscribe = false
          console.error(err)
          this.snack_text = `Ajaj! Něco se pokazilo. ${this.responseError(err)}`
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
  },
}
</script>

<style>

</style>
